@import "../../scss/variables.scss";

#works {
  display: flex;
  flex-direction: column;
  h2 {
    @include section-title;
  }
}

@media only screen and (max-width: 600px) {
  #works {
    align-items: center;
    h2 {
      @include section-title($size: 2rem);
    }
  }
}
