.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
  background-color: black;
  margin-top: 2rem;
  a {
    color: white;
  }
}