@mixin text-styles {
  mix-blend-mode: difference;
  background: transparent;
  @-moz-document url-prefix() {
    mix-blend-mode: unset;
    background: transparent;
  }
}

@mixin section-title($size: 3rem) {
  text-align: center;
  font-size: $size;
  font-variation-settings: "wght" 300, "CONT" 400;
  text-decoration: underline;
}

@mixin button-styles($width: 5rem, $height: 5rem, $top: 50%, $left: 90%, $fontSize: 1rem) {
  position: relative;
  text-align: center;
  border: 1px solid black;
  overflow: hidden;
  @include text-styles;
  background-clip: text;
  font-size: $fontSize;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    width: $width;
    height: $height;
    border-radius: 50%;
    position: absolute;
    top: $top;
    left: $left;
    transform-origin: center;
    transition: transform 300ms ease-in-out;
  }
  &:before {
    @supports (mix-blend-mode: difference) {
      background-color: white;
      mix-blend-mode: difference;
    }
    z-index: 1;
    pointer-events: none;
  }
  &:after {
    background-color: #e20074;
    @supports (mix-blend-mode: difference) {
      background-color: adjust-hue(
        #e20074,
        90
      ); //#e20074; <- desired color, complementary color does not match exactly
      filter: hue-rotate(-180deg) brightness(2);
    }
    z-index: -1;
  }
  &:hover {
    &:before,
    &:after {
      transform: scale(9);
    }
  }
}
