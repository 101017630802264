@import "../../scss/variables.scss";

#landing {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  .nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    .dropdown {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 5rem;
      margin-left: 2rem;
      text-align: center;
      border: 1px solid black;
      @include text-styles;
      background-clip: text;
      padding: 1rem 0.5rem;
      cursor: pointer;
      &-button {
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;
        min-width: 5rem;
      }
      &-list {
        min-width: 6rem;
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 100%;
        left: 0%;
        outline: 1px solid black;
        padding: 0rem 0rem;
        transform: translate(0px, 0.4px);
        li {
          cursor: pointer;
          padding: 1rem 0.5rem;
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
    .nav-header_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 1rem;
      list-style: none;
      margin-right: 1rem;
      padding: 0;
      width: 100%;
      .nav-element {
        font-size: 1rem;
        font-variation-settings: "wght" 400, "CONT" 400;
        @include button-styles;
        &:nth-child(1) {
          grid-area: 1 / 3 / 2 / 4;
        }
        &:nth-child(2) {
          grid-area: 1 / 4 / 2 / 5;
        }
        a {
          display: inline-block;
          padding: 1rem 0.5rem;
        }
      }
    }
  }
  .hero {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    &-presentation {
      padding-left: 10vw;
      font-size: 1.5rem;
      font-variation-settings: "CONT" 700, "wght" 300;
      margin-bottom: 0;
    }
    &-name {
      padding-left: 15vw;
      font-size: 5rem;
      margin: 0;
      animation: changeFont 3.5s infinite ease-in-out;
      @for $line from 1 through 4 {
        &:nth-child(#{$line}) {
          animation-delay: #{$line * 0.3s};
        }
      }
    }
    &-description {
      padding-left: 15vw;
      margin-top: 1vh;
    }
  }
  .scroll-svg {
    position: relative;
    margin: 4rem auto;
    &::before {
      content: "";
      position: absolute;
      width: 0.1rem;
      height: 3rem;
      background-color: black;
      border-radius: 50%;
      top: 3rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      content: "";
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      background-color: black;
      border-radius: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: callToScroll 3s infinite ease-in-out;
    }
  }
}

@keyframes changeFont {
  0% {
    font-variation-settings: "wght" 100, "CONT" 100;
  }

  50% {
    font-variation-settings: "wght" 900, "CONT" 1000;
  }

  100% {
    font-variation-settings: "wght" 100, "CONT" 100;
  }
}

@keyframes callToScroll {
  0% {
    top: 1.5rem;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    top: 4.5rem;
  }
}

@media only screen and (max-width: 600px) {
  #landing {
    .nav-header {
      justify-content: end;
      .dropdown {
        margin: 1rem;
      }
      .nav-header_list {
        display: none;
      }
    }
    .hero {
      &-presentation {
        font-size: 1rem;
      }
      &-name {
        font-size: 3rem;
      }
      &-description {
        font-size: 0.9rem;
      }
    }
    .scroll-svg {
      margin: 0rem auto 8rem auto;
    }
  }
}
