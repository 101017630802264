@import "../../scss/variables.scss";

.card-container_left {
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: 65vh;
  margin-top: 3rem;
  .card-img_container {
    position: relative;
    outline: black 1px solid;
    height: 45vh;
    width: 45vw;
    z-index: -1;
    .card-img {
      object-fit: cover;
      height: 45vh;
      width: 45vw;
    }
  }
  .card-text {
    display: flex;
    flex-direction: column;
    &_title {
      text-align: right;
      font-size: 1.5rem;
      margin: 0.5rem 0 1rem 0;
    }
    &_description {
      padding-left: 35%;
      font-size: 0.8rem;
      text-align: right;
    }
    &_logos_github {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      .github-button {
        @include button-styles($top: 45%, $left: 80%);
        height: 2.3rem;
        width: 5rem;
        margin-right: 0rem;
        width: 30%;
      }
      .logos {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 30%;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        z-index: -1;
        li {
          width: 2.3rem;
          height: 2.3rem;
          img {
            width: 2.3rem;
            height: 2.3rem;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .card-container_left {
    width: 85vw;
    height: 50vh;
    margin-top: 2rem;
    .card-img_container {
      height: 25vh;
      width: 85vw;
      .card-img {
        height: 25vh;
        width: 85vw;
      }
    }
    .card-text {
      &_description {
        padding-left: 0%;
        font-size: 0.8rem;
      }
      &_logos_github {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        .github-button {
          margin-right: 2rem;
        }
      }
    }
  }
}
