@import "../../scss/variables.scss";

#experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    @include section-title;
  }
  .experience-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    p {
      margin-top: 0.5rem;
      font-size: 1.3rem;
      line-height: 2rem;
    }
    .last-span {
      margin-top: 1.7rem;
      text-align: center;
      font-size: 1rem;
    }
    button {
      margin: 2rem 0 2rem 0;
      padding: 1rem 3rem;
      @include button-styles($top: 40%, $left: 70%);
      z-index: 1;
    }
    .logos {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 5rem;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  #experience {
    h2 {
      @include section-title($size: 2rem);
    }
    .experience-wrapper {
      width: 70%;
      p {
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
      .last-span {
        width: 50vw;
        margin-top: 1.7rem;
        text-align: center;
        font-size: 0.7rem;
      }
      button {
        margin: 1rem 0 0 0;
        padding: 0.5rem 2rem;
        @include button-styles($top: 40%, $left: 70%, $fontSize: 0.8rem);
      }
      .logos {
        flex-direction: column;
        gap: 0rem;
        align-items: center;
        justify-content: center;
        margin-top: 0rem;
        margin-bottom: 2rem;
      }
    }
  }
}